import React from "react"
import NavbarThree from "../components/App/NavbarThree"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import MainBanner from "../components/SolutionPersonalBranding/Sd1mainBanner"
import FeaturedService from "../components/SolutionPersonalBranding/Sd2featuredService"
import PromotionProfilProfessionnel from "../components/SolutionPersonalBranding/Sd3promotionProfilProfessionnel"
import Testimonials from "../components/SolutionPersonalBranding/Sd4testimonials"
import StartProject from "../components/SolutionPersonalBranding/Sd5startProject"

const Home = ({location}) => {
  return (
    <Layout path={location.pathname}>
      <NavbarThree />

      <MainBanner />
      <FeaturedService />
      <PromotionProfilProfessionnel />
      <Testimonials />
      <StartProject />

      <Footer />
    </Layout>
  )
}

export default Home;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;